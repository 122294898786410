
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/business-in-armenia",
      function () {
        return require("private-next-pages/business-in-armenia/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/business-in-armenia"])
      });
    }
  