import { memo as Memo, useMemo, useState, useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from 'context'

//* Components
import { Page, AnimationWrapp } from 'components/common'
import { BusinessInArmeniaTop, BusinessInArmeniaForm, BusinessInArmeniaVideoSlider } from 'components/pages'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

//*Helpers
import { arrayGenChild } from 'helpers'

//* Styles
import BusinessInArmeniaStyle from 'styles/pages/BusinessInArmeniaStyle'

//* Lottie Animations
import animationJSON1 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime1.json'
import animationJSON2 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime2.json'
import animationJSON3 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime3.json'
import animationJSON4 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime4.json'
import animationJSON5 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime5.json'
import animationJSON6 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime6.json'
import animationJSON7 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime7.json'
import animationJSON8 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime8.json'
import animationJSON9 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime9.json'
import animationJSON10 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime10.json'
import animationJSON11 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime11.json'
import animationJSON12 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime12.json'
import animationJSON13 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime13.json'
import animationJSON14 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime14.json'
import animationJSON15 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime15.json'
import animationJSON16 from 'lottieAnimation/businessInArmenia/BusinessInArmeniaAnime16.json'

const BusinessInArmenia = Memo(({ pages, global, pageParams, selectedLang, preloader, ...props }) => {
    //! Page Data
    const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])
    const currency = useMemo(() => global?.[selectedLang]?.currency, [global, selectedLang])

    const staticData = useMemo(
        () => ({
            top: [
                {
                    animationJSON: animationJSON6,
                    title: 'RegisterJointStockCompany',
                    link: ' https://armenia.eregulations.org/procedure/6?l=en',
                },
                {
                    animationJSON: animationJSON7,
                    title: 'RegisterBranchForeignCompany',
                    link: 'https://armenia.eregulations.org/procedure/7?l=en',
                },
                {
                    animationJSON: animationJSON16,
                    title: 'RegisterIndividualEntrepreneur',
                    link: 'https://armenia.eregulations.org/procedure/5?l=en',
                },
                {
                    animationJSON: animationJSON2,
                    title: 'RegisterLimitedLiabilityCompany',
                    link: 'https://armenia.eregulations.org/procedure/3?l=en',
                },
            ],
            video: [
                {
                    src: 'https://www.youtube.com/watch?v=gZ002NjJg9I',
                    title: 'InformationTechnologies',
                    img: '/images/businessInArmenia/ITsector.jpg',
                    pdf: '/downloadPDF/IT.pdf',
                },
                {
                    src: 'https://www.youtube.com/watch?v=jyIHFC6Q4Ek',
                    title: 'Energy',
                    img: '/images/businessInArmenia/RenewableEnergy.jpg',
                    pdf: '/downloadPDF/Energy.pdf',
                },
                {
                    src: 'https://www.youtube.com/watch?v=MIZ9MfyMDYo',
                    title: 'Pharmacy',
                    img: '/images/businessInArmenia/Pharmacy.jpg',
                    pdf: '/downloadPDF/Pharmacy.pdf',
                },
                {
                    src: 'https://www.youtube.com/watch?v=wF0Q5ZsH3Pg',
                    title: 'Agroindustry',
                    img: '/images/businessInArmenia/Agriculture.jpg',
                    pdf: '/downloadPDF/Agriculture.pdf',
                },
                {
                    src: 'https://www.youtube.com/watch?v=Avtj3GhZr2c',
                    title: 'Textile',
                    img: '/images/businessInArmenia/Textile.jpg',
                    pdf: '/downloadPDF/Textile.pdf',
                },
                {
                    src: 'https://www.youtube.com/watch?v=whbWf53RBXs',
                    title: 'Jewelry',
                    img: '/images/businessInArmenia/JewelrySector.jpg',
                    pdf: '/downloadPDF/Jewelry.pdf',
                },
            ],
            bottom: [
                {
                    animationJSON: animationJSON8,
                    title: 'ObtainResidency',
                    link: 'https://armenia.eregulations.org/objective/13?l=en',
                },
                {
                    animationJSON: animationJSON15,
                    title: 'ObtainSignature',
                    link: 'https://armenia.eregulations.org/objective/34?l=en',
                },
                {
                    animationJSON: animationJSON3,
                    title: 'RegisterTrademark',
                    link: 'https://armenia.eregulations.org/procedure/16?l=en',
                },
                {
                    animationJSON: animationJSON1,
                    title: 'RegisterIndustrialDesign',
                    link: 'https://armenia.eregulations.org/procedure/15?l=en',
                },
                {
                    animationJSON: animationJSON10,
                    title: 'RegisterPropertyRights',
                    link: 'https://armenia.eregulations.org/procedure/21?l=en',
                },
                {
                    animationJSON: animationJSON9,
                    title: 'BecomeResidentFreeEconomicZone',
                    link: 'https://armenia.eregulations.org/procedure/23?l=en',
                },
                {
                    animationJSON: animationJSON11,
                    title: 'ObtainCountryOriginCertificate',
                    link: 'https://armenia.eregulations.org/procedure/26?l=en',
                },
                {
                    animationJSON: animationJSON4,
                    title: 'ObtainStatementOriginExportingEU',
                    link: 'https://armenia.eregulations.org/procedure/40?l=en',
                },
                {
                    animationJSON: animationJSON14,
                    title: 'GetConstructionPermit',
                    link: 'https://armenia.eregulations.org/procedure/28?l=en',
                },
                {
                    animationJSON: animationJSON12,
                    title: 'GetElectricityConnection',
                    link: 'https://armenia.eregulations.org/procedure/30?l=en',
                },
                {
                    animationJSON: animationJSON13,
                    title: 'GetWaterConnection',
                    link: 'https://armenia.eregulations.org/procedure/32?l=en',
                },
                {
                    animationJSON: animationJSON5,
                    title: 'GetGasConnection',
                    link: 'https://armenia.eregulations.org/procedure/31?l=en',
                },
            ],
        }),
        []
    )

    //! States
    const [secObj, setSecObj] = useState()
    const [secObjIndividual, setSecObjIndividual] = useState()

    //! Refs
    const Ref1 = useRef()
    const Ref2 = useRef()
    const Ref3 = useRef()
    const Ref4 = useRef()

    //! Animation
    useEffect(() => {
        if (pageData && Ref1?.current?.[1] && Ref1?.current?.[3] && Ref2?.current?.[1] && Ref2?.current?.[6] && Ref4?.current?.[2] && !preloader) {
            setSecObj([
                arrayGenChild(Ref1.current[0][0].childNodes),
                Ref1.current[1].current,
                arrayGenChild(Ref1.current[2][0].childNodes),
                Ref1.current[3],
                arrayGenChild(Ref2.current[0]),
                Ref2.current[1],
                arrayGenChild(Ref3.current.childNodes[0].childNodes),
                arrayGenChild(Ref4.current[0][0].childNodes),
                Ref4.current[1].current,
                Ref4.current[2],
            ])

            setSecObjIndividual([/* Ref2.current[2], */ Ref2.current[3], Ref2.current[4], Ref2.current[5], Ref2.current[6]])
        }
    }, [Ref1.current, Ref2.current, Ref3.current, Ref4.current, pageData, selectedLang, preloader])

    return (
        <BusinessInArmeniaStyle>
            <Page
                className='business-in-Armenia'
                preloaderState={pageData ? true : false}
                meta={{
                    title: pageData?.content?.meta?.title,
                    description: pageData?.content?.meta.description,
                    keywords: pageData?.content?.meta.keywords,
                    image: pageData?.content.meta_image,
                }}
            >
                {pageData && (
                    <AnimationWrapp state={secObj} formItems={secObjIndividual}>
                        <BusinessInArmeniaTop
                            ref={Ref1}
                            title={pageData.content.section_1_title}
                            text={pageData.content.section_1_text}
                            data={staticData.top}
                        />

                        <BusinessInArmeniaForm
                            ref={Ref2}
                            currency={currency}
                            leftText={pageData.content.section_3_left_text}
                            rightText={pageData.content.section_3_right_text}
                        />

                        <BusinessInArmeniaVideoSlider ref={Ref3} title={pageData.content.section_4_title} video={staticData.video} />

                        <BusinessInArmeniaTop
                            ref={Ref4}
                            title={pageData.content.section_5_title}
                            text={pageData.content.section_5_text}
                            type={2}
                            data={staticData.bottom}
                        />
                    </AnimationWrapp>
                )}
            </Page>
        </BusinessInArmeniaStyle>
    )
})

export default withLanguageContext(withUIContext(withDataContext(BusinessInArmenia, ['pages', 'global']), ['preloader']), ['selectedLang'])
