import styled from 'styled-components'

const BusinessInArmeniaStyle = styled.div.attrs({})`
	--topInfoWidth: 59%;
	--regMetodItemWidth: calc(25% - var(--sp3x));
	--regMetodItemMarg: var(--sp4x);
	--titleMargBottom: var(--sp4x);

	overflow: hidden;

	.BusinessInArmeniaTopInfo {
		margin-bottom: var(--pageFromHeaderMargin);
		.title {
			width: calc(100% - (var(--topInfoWidth) + var(--sp5x)));
		}
		.description {
			width: calc(var(--topInfoWidth) - var(--sp5x));
		}
	}

	.title {
		margin-bottom: var(--titleMargBottom);
	}

	.BusinessInArmeniaTopRegMetod {
		.BusinessInArmeniaRegMetodItem {
			transform: translateY(100%);
			width: var(--regMetodItemWidth);
			padding: var(--sp4x) var(--sp3x);
			margin-bottom: var(--sp4x);
			text-align: center;
			border-radius: var(--borderMd);
			border: 1px solid var(--extraLightGrey);
			background: var(--white);
			transition: background-color var(--trTime2) ease;
			display: inline-block;
			&:hover {
				background: var(--ultraLightGrey);
			}
			&.bordNone {
				border: 0;
                &:hover {
                    background: transparent;
                }
			}

			.LottieWrapp {
				padding-top: 80%;
				width: 100%;
			}
			.RegMetodItemTitle {
				margin-top: var(--sp5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--topInfoWidth: 52%;
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--topInfoWidth: 52%;
		--regMetodItemWidth: calc(25% - var(--sp2x));
		--regMetodItemMarg: var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		--titleMargBottom: var(--sp3x);
		--regMetodItemWidth: 34%;
		--regMetodItemMarg: var(--sp3x);

		.BusinessInArmeniaTopRegMetod {
			.BusinessInArmeniaRegMetodItem {
				margin-right: var(--sp4x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--topInfoWidth: 100%;
		--regMetodItemWidth: calc(50% - var(--sp2x));
		--regMetodItemMarg: var(--sp3x);
		--titleMargBottom: var(--sp2x);

		.BusinessInArmeniaTopInfo {
			.title {
				width: var(--topInfoWidth);
			}
			.description {
				width: var(--topInfoWidth);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--topInfoWidth: 100%;
		--regMetodItemWidth: 100%;
		--regMetodItemMarg: var(--sp2x);
		--titleMargBottom: var(--sp2x);

		.title {
			max-width: 85%;
		}

		.BusinessInArmeniaTopInfo {
			.title {
				width: var(--topInfoWidth);
			}
			.description {
				width: var(--topInfoWidth);
			}
		}
	}
`
export default BusinessInArmeniaStyle
